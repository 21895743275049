// extracted by mini-css-extract-plugin
export var container = "course-module--container--gqO_L";
export var headerBar = "course-module--headerBar--3fT6w";
export var options = "course-module--options--FV-7S";
export var titles = "course-module--titles--dYRrs";
export var headerLeft = "course-module--headerLeft--1qigW";
export var courseTitle = "course-module--courseTitle--hcGv3";
export var lessonName = "course-module--lessonName--27z4P";
export var controls = "course-module--controls--2Q3SF";
export var avatarIcon = "course-module--avatarIcon--1dx1T";
export var statusIndicator = "course-module--statusIndicator--UXZyH";